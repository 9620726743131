export const projectThumbnails = {
	uxindia23: {
		thumbnail: require('./uxindia23/thumb.webp'),
		overlay: require('./uxindia23/overlay.webp'),
	},
	wsummit23: {
		thumbnail: require('./wsummit23/thumb.webp'),
		overlay: require('./wsummit23/overlay.webp'),
	},
	burnco: {
		thumbnail: require('./burnco/thumb.webp'),
		overlay: require('./burnco/overlay.webp'),
	},
	yoptima: {
		thumbnail: require('./yoptima/thumb.webp'),
		overlay: require('./yoptima/overlay.webp'),
	},
	qualizeal: {
		thumbnail: require('./qualizeal/thumb.webp'),
		overlay: require('./qualizeal/overlay.webp'),
	},
	happening: {
		thumbnail: require('./happening/thumb.webp'),
		overlay: require('./happening/overlay.webp'),
	},
};

// export default projectThumbnails;
