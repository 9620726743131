import React from 'react';
import { useState, useEffect } from 'react';
import './navbar.css';
import logo from '../../assets/logo.png';

const Navbar = () => {
	const [sticky, setSticky] = useState('');

	useEffect(() => {
		window.addEventListener('scroll', setNavAsSticky);

		return () => {
			window.removeEventListener('scroll', setNavAsSticky);
		};
	}, []);

	const setNavAsSticky = () => {
		if (window !== undefined) {
			let windowHeight = window.scrollY;

			windowHeight > 50 ? setSticky('is-sticky') : setSticky('');
		}
	};

	return (
		<div className={`site__navbar section__padding-horizontal ${sticky}`}>
			<div className='site__navbar__container'>
				<div className='site__navbar-logo'>
					<img src={logo} alt='Kushagra Srivastava'></img>
				</div>

				<div className='site__navbar-links'>
					<a
						href='mailto:hello@kushagrasrivastava.com'
						className='button'
					>
						Contact
					</a>
				</div>
			</div>
		</div>
	);
};

export default Navbar;
