import React from 'react';
import './project.css';

const Project = ({
	title,
	type,
	thumbnail,
	overlay,
	hyperlink,
	forwardedRef,
}) => {
	return (
		<a
			className='project__card'
			href={hyperlink}
			target='_blank'
			rel='noreferrer'
			ref={forwardedRef}
		>
			<div className='project__card__image-container'>
				<img
					src={thumbnail}
					alt={title}
					className='project__card_image-container_thumb'
				/>
				<img
					src={overlay}
					alt={title}
					className='project__card_image-container_overlay'
				/>
			</div>

			<div className='project__card_details'>
				<h3 className='project__card_details_name'>{title}</h3>
				<h4 className='project__card_details_type'>{type}</h4>
			</div>
		</a>
	);
};

export default Project;
