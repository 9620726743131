import React, { useEffect } from 'react';

import luxy from 'luxy.js';

import { Navbar, Preloader } from './components';
import { Footer, Hero, Projects } from './containers';
import './app.css';

const App = () => {
	useEffect(() => {
		luxy.init({
			wrapper: '#luxy',
			wrapperSpeed: 0.04,
			targets: '.luxy-el',
		});
	}, []);

	return (
		<div className='app'>
			{/* <Preloader /> */}
			<Navbar />
			<div className='main' id='luxy'>
				<Hero />
				<Projects />
				<Footer />
			</div>
		</div>
	);
};

export default App;
