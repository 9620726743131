import React from 'react';
import './footer.css';

import { FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const Footer = () => {
	return (
		<div className='footer section__padding-horizontal section__padding-vertical'>
			<div className='footer__container'>
				<div className='footer__socials'>
					<a
						href='https://www.linkedin.com/in/thekushagrasrivastava/'
						className='footer__social-link'
						target='_blank'
						rel='noreferrer'
					>
						<FaLinkedinIn />
					</a>

					<a
						href='https://www.instagram.com/thekushagrasrivastava/'
						className='footer__social-link'
						target='_blank'
						rel='noreferrer'
					>
						<FaInstagram />
					</a>
				</div>

				<div className='footer__title'>
					Looking for a WordPress Developer?
				</div>

				<div className='footer__contact-button'>
					<a
						href='mailto:hello@kushagrasrivastava.com'
						className='button'
					>
						Get In Touch
					</a>
				</div>
			</div>
		</div>
	);
};

export default Footer;
