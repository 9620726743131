import React from 'react';
import { useEffect, useRef } from 'react';

import { Project } from '../../components';
import './projects.css';
import { projectThumbnails } from '../../assets/projects'; // project images
import useMultiRefs from '../../utility/multiRef';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Projects = () => {
	const sectionTrigger = useRef(null);
	const separator = useRef(null);
	const sectionTitle = useRef(null);

	const [multiRef, addMultiRef] = useMultiRefs();

	useEffect(() => {
		const sectionTriggerElement = sectionTrigger.current;
		const separatorElement = separator.current;
		const sectionTitleElement = sectionTitle.current;

		gsap.timeline({
			paused: true,
			scrollTrigger: {
				trigger: sectionTriggerElement,
				start: 'top 75%',
			},
		})
			.fromTo(
				separatorElement,
				{ maxWidth: 0 },
				{ maxWidth: '100%', duration: 1 }
			)
			.fromTo(
				sectionTitleElement,
				{ opacity: 0 },
				{ opacity: 1, duration: 2 }
			);

		// MultiRefs for Projects
		const elements = multiRef();

		elements.forEach((el, i) => {
			gsap.timeline({
				paused: true,
				scrollTrigger: {
					trigger: el,
					start: 'top 99%',
				},
			}).fromTo(
				el,
				{ top: 100, opacity: 0 },
				{ top: 0, opacity: 1, duration: 2 }
			);
		});
	}, []);

	return (
		<div
			className='projects section__padding-horizontal section__padding-vertical'
			ref={sectionTrigger}
		>
			<div className='projects__container'>
				<div className='separator' ref={separator}></div>
				<div className='section__title-container'>
					<h2 className='section__title' ref={sectionTitle}>
						Featured Projects
					</h2>
				</div>

				<div className='projects__collection'>
					<Project
						title='UXINDIA 2023'
						type='Development'
						thumbnail={projectThumbnails.uxindia23.thumbnail}
						overlay={projectThumbnails.uxindia23.overlay}
						hyperlink='https://2023.ux-india.org/'
						forwardedRef={addMultiRef}
					/>

					<Project
						title='WSummit 2023'
						type='Development'
						thumbnail={projectThumbnails.wsummit23.thumbnail}
						overlay={projectThumbnails.wsummit23.overlay}
						hyperlink='https://wsummit.umo.design/'
						forwardedRef={addMultiRef}
					/>

					<Project
						title='BURNCO'
						type='Development'
						thumbnail={projectThumbnails.burnco.thumbnail}
						overlay={projectThumbnails.burnco.overlay}
						hyperlink='https://www.burnco.com/'
						forwardedRef={addMultiRef}
					/>

					<Project
						title='YOptima'
						type='Design & Development'
						thumbnail={projectThumbnails.yoptima.thumbnail}
						overlay={projectThumbnails.yoptima.overlay}
						hyperlink='https://yoptima.com/'
						forwardedRef={addMultiRef}
					/>

					<Project
						title='Qualizeal'
						type='Development'
						thumbnail={projectThumbnails.qualizeal.thumbnail}
						overlay={projectThumbnails.qualizeal.overlay}
						hyperlink='https://qualizeal.com/'
						forwardedRef={addMultiRef}
					/>

					<Project
						title='Happening'
						type='Development'
						thumbnail={projectThumbnails.happening.thumbnail}
						overlay={projectThumbnails.happening.overlay}
						hyperlink='https://www.happening.design/'
						forwardedRef={addMultiRef}
					/>
				</div>
			</div>
		</div>
	);
};

export default Projects;
