import React from 'react';
import { useEffect, useRef } from 'react';
import './hero.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
	const heroTitleFirst = useRef(null);
	const heroTitleSecond = useRef(null);
	const heroDesc = useRef(null);

	useEffect(() => {
		const elementOne = heroTitleFirst.current;
		const elementTwo = heroTitleSecond.current;
		const elementThree = heroDesc.current;
		// const body = document.getElementByTagName('body');

		gsap.timeline({
			// paused: true,
			// scrollTrigger: {
			// 	trigger: heroTrigger,
			// 	start: 'top',
			// },
		})
			.fromTo(
				document.body,
				{ opacity: 0 },
				{ opacity: 1, duration: 1, delay: 1 }
			)
			.fromTo(
				elementOne,
				{ top: 25, opacity: 0 },
				{ top: 0, opacity: 1, duration: 0.75 }
			)
			.fromTo(
				elementTwo,
				{ top: 25, opacity: 0 },
				{ top: 0, opacity: 1, duration: 0.75 }
			)
			.fromTo(
				elementThree,
				{ opacity: 0 },
				{ opacity: 1, duration: 0.75 }
			);
	}, []);

	return (
		<div className='hero section__padding-horizontal section__padding-vertical'>
			<div className='hero__container'>
				<h1 className='hero__title'>
					<span ref={heroTitleFirst}>Frontend Designer & </span>
					<br />
					<span ref={heroTitleSecond}>WordPress Developer</span>
				</h1>

				<h3 className='hero__subtitle' ref={heroDesc}>
					I'm Kushagra Srivastava, a frontend designer and creative
					Wordpress developer - I collaborate with brands globally to
					design impactful, mission-focused websites that drive
					results and achieve business goals
				</h3>
			</div>
		</div>
	);
};

export default Hero;
